import React, { useState, useEffect, useRef } from "react";

import { Box, IconButton, Paper, Typography } from "@mui/material";
import {
  ZoomIn,
  ZoomOut,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

interface props {
  url: string;
}

function PdfViewer({ url }: props) {
  const [pageCount, setPageCount] = useState<number>(1);
  const [page, setPage] = useState(1);
  const [scale, setScale] = useState(1);

  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(100);

  const onZoomIn = () => {
    setScale((oldScale) => oldScale + 0.5);
  };

  const onZoomOut = () => {
    setScale((oldScale) => oldScale - 0.5);
  };

  const nextPage = () => {
    setPage((oldPage) => oldPage + 1);
  };

  const lastPage = () => {
    setPage((oldPage) => oldPage - 1);
  };

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
    }
  }, [containerRef]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPageCount(numPages);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          maxWidth: 800,
          width: "90%",
          overflowY: "auto",
          overflowX: scale === 1 ? "hidden" : "auto",
        }}
        className={scale !== 1 ? "swiper-no-swiping" : ""}
      >
        <Box
          sx={{ width: "100%", bgcolor: "#2e2e2e", color: "white" }}
          className="swiper-no-swiping"
        >
          <IconButton color="inherit" size="large" onClick={onZoomIn}>
            <ZoomIn />
          </IconButton>
          <IconButton color="inherit" size="large" onClick={onZoomOut}>
            <ZoomOut />
          </IconButton>
        </Box>

        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={page} width={width} scale={scale} />
        </Document>
        <Paper
          sx={{
            position: "absolute",
            zIndex: 1,
            bottom: 75,
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            alignItems: "center",
          }}
          className="swiper-no-swiping"
        >
          <Box>
            <IconButton size="large" onClick={lastPage} disabled={page === 1}>
              <ChevronLeft />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="overline">Page:</Typography>
            <Typography variant="h6">
              {page} / {pageCount}
            </Typography>
          </Box>
          <Box>
            <IconButton
              size="large"
              onClick={nextPage}
              disabled={page === pageCount}
            >
              <ChevronRight />
            </IconButton>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default PdfViewer;
