import { useState, useEffect } from "react";
import axios from "axios";

function useGetTabs(kingsRecordId: string | null, secret: string | null) {
  const [data, setData] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<number | null>(null);

  useEffect(() => {
    let active = true;

    const getFileUrls = async () => {
      if (!kingsRecordId || !secret) {
        setLoading(false);
        setError(400);
        setData([]);
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const response = await axios.get("/images/tabs", {
          params: {
            kingsRecordId,
            secret,
          },
        });

        if (active) {
          setData(response.data.tabs);
        }
      } catch (err) {
        if (active) {
          if (axios.isAxiosError(err)) {
            if (err.response?.status) {
              setError(Number(err.response.status));
            } else {
              setError(500);
            }
          } else {
            setError(500);
          }
        }
      }
      if (active) setLoading(false);
    };

    getFileUrls();

    return () => {
      active = false;
    };
  }, [kingsRecordId, secret]);

  return { data, loading, error };
}

export default useGetTabs;
