import React from "react";

import { useSearchParams } from "react-router-dom";

import { Stack, Box, Typography, CircularProgress } from "@mui/material";

import useGetFileUrls from "../../../hooks/useGetFileUrls";

import FileRenderer from "../../FileRenderer";

import { Navigation, Pagination, Keyboard, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";

interface FileViewProps {
  s3ObjectName: string;
}
function FileView({ s3ObjectName }: FileViewProps) {
  const [searchParams] = useSearchParams();
  const { data, loading, error } = useGetFileUrls(
    s3ObjectName,
    searchParams.get("kingsRecordId"),
    searchParams.get("secret")
  );

  if (loading) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    if (error === 400) {
      return (
        <Stack alignItems="center">
          <Typography variant="h3">Something went wrong!</Typography>
          <Typography variant="h4">
            Is the request structured properly?
          </Typography>
        </Stack>
      );
    }
    if (error === 401) {
      return (
        <Stack alignItems="center">
          <Typography variant="h3">Unauthorized!</Typography>
        </Stack>
      );
    }

    return (
      <Stack alignItems="center">
        <Typography variant="h3">Something unknown went wrong!</Typography>
        <Typography variant="h4">That kinda sucks... ¬_¬</Typography>
      </Stack>
    );
  }

  return (
    <Box
      sx={{
        bgcolor: "lightgrey",
        height: `calc(${window.innerHeight}px - 90px)`,
        pt: 2,
        pb: 3,
      }}
    >
      <Swiper
        modules={[Pagination, Navigation, Keyboard, Zoom]}
        loop
        pagination={{
          clickable: true,
        }}
        navigation
        keyboard={{
          enabled: true,
        }}
        zoom
        style={{ width: "100%", height: "100%" }}
      >
        {data.map((url, index) => (
          <SwiperSlide key={url} virtualIndex={index}>
            <FileRenderer url={url} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}

export default FileView;
