import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./components/pages/Home";

function App() {
  const [renderPage, setRenderPage] = useState(false);
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            renderPage ? (
              <Home />
            ) : (
              <Box
                sx={{
                  width: "100vw",
                  height: "100vh",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setRenderPage(true);
                  }}
                >
                  Open Gallery
                </Button>
              </Box>
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
