import React from "react";

import { Box, Typography } from "@mui/material";

import PdfViewer from "../PdfViewer";

interface props {
  url: string;
}

function FileRenderer({ url }: props) {
  const path = url.split("?")[0];

  const parts = path.split("/");
  const name = parts[parts.length - 1];

  if (path.substring(path.length - 3) === "pdf") {
    return (
      <Box className="swiper-zoom-container">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography>{name}</Typography>
          <PdfViewer url={url} />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      className="swiper-zoom-container"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography>{name}</Typography>
      <Box
        sx={{ display: "flex", justifyContent: "center", overflowY: "auto" }}
      >
        <img
          src={url}
          alt="Failed to load."
          style={{ maxWidth: 800, width: "90%", objectFit: "contain" }}
        />
      </Box>
    </Box>
  );
}

export default FileRenderer;
