import React, { useEffect, useState } from "react";

import {
  Box,
  Tabs,
  Tab,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";

import FileView from "../FileView";
import useGetTabs from "../../../hooks/useGetTabs";
import { useSearchParams } from "react-router-dom";

function Home() {
  const [tab, setTab] = useState("");

  const [searchParams] = useSearchParams();
  const { data, loading, error } = useGetTabs(
    searchParams.get("kingsRecordId"),
    searchParams.get("secret")
  );

  useEffect(() => {
    setTab(data[0]);
  }, [data]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    if (error === 400) {
      return (
        <Stack alignItems="center">
          <Typography variant="h3">Something went wrong!</Typography>
          <Typography variant="h4">
            Is the request structured properly?
          </Typography>
        </Stack>
      );
    }
    if (error === 401) {
      return (
        <Stack alignItems="center">
          <Typography variant="h3">Unauthorized!</Typography>
        </Stack>
      );
    }

    return (
      <Stack alignItems="center">
        <Typography variant="h3">Something unknown went wrong!</Typography>
        <Typography variant="h4">That kinda sucks... ¬_¬</Typography>
      </Stack>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: window.innerHeight,
      }}
    >
      <Tabs value={tab} onChange={handleChange}>
        {data.map(tab => (
          <Tab key={tab} value={tab} label={tab} />
        ))}
      </Tabs>
      <FileView s3ObjectName={tab} />
    </Box>
  );
}

export default Home;
